<template>
  <div class="auto__search-postal-code-input-container">
    <q-dialog :ref="modalRef" v-cloak full-width position="bottom">
      <DialogHeader header="Location" header-icon="sym_r_place" />

      <q-card style="width: 100%">
        <q-form @submit="submitPostalCodeInputForm()">
          <q-card-section class="row q-col-gutter-sm q-py-lg">
            <q-input
              filled
              v-model="postalCode"
              label="Zip Code"
              class="col-12"
              :error="postalCodeError != null"
              :error-message="postalCodeError"
              inputmode="numeric"
              pattern="[0-9]*"
              mask="#####"
            />
            <q-select
              filled
              class="col-12"
              v-model="radius"
              :options="locationRadiusOptions"
              label="Search Within"
              emit-value
              map-options
            />
          </q-card-section>
          <q-card-section class="q-pt-none">
            <q-btn
              label="Apply"
              type="submit"
              color="secondary"
              class="full-width"
              :disable="disableSubmit"
              :loading="disableSubmit"
            />
          </q-card-section>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogHeader from "@/components/UI/DialogHeader.vue";

export default {
  name: "PostalCodeInputDialog",
  components: { DialogHeader },
  data() {
    return {
      modalRef: "autoSearchPostalCodeInputModal",
      disableSubmit: false,
      postalCode: this.$store.state.search.location.postalCode,
      postalCodeError: null,
      radius: this.$store.state.search.location.radius
    };
  },
  computed: {
    locationRadiusOptions() {
      let radiuses = [
        "10",
        "25",
        "50",
        "75",
        "100",
        "150",
        "200",
        "250",
        "500"
      ];
      let options = [];
      radiuses.forEach(radius => {
        options.push({ label: radius + " miles", value: radius });
      });
      return options;
    }
  },
  mounted() {},
  methods: {
    show() {
      this.$refs[this.modalRef].show();
    },
    hide() {
      this.$refs[this.modalRef].hide();
    },
    submitPostalCodeInputForm() {
      this.disableSubmit = true;

      this.$store
        .dispatch("geolocation/VALIDATE_POSTAL_CODE", this.postalCode)
        .then(locationData => {
          this.postalCodeError = null;

          this.$store.commit("search/SET_LOCATION", {
            postalCode: this.postalCode,
            city: locationData["city"],
            state: locationData["state"],
            radius: this.radius
          });

          this.$store.commit("search/RESET_PAGINATION");
          this.$store.commit("search/SET_LOADING");
          this.hide();
        })
        .catch(error => {
          this.postalCodeError = error;
        })
        .finally(() => {
          this.disableSubmit = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
