<template>
  <q-dialog
      :ref="modalRef"
      v-cloak
      full-width
      position="bottom"
      class="--fullscreen-height__padded"
  >
    <DialogHeader header="Filters" header-icon="sym_r_tune" />

    <q-card
        class="bg-background"
        style="position: relative; width: 100%; height: 100%"
    >
      <q-form
          @submit="filterSearchResults"
          @reset="resetFilters"
          class="auto__srp__filters-mobile"
      >
        <q-list separator class="bg-background">
          <q-expansion-item expand-separator label="Make & Model">
            <q-expansion-item
                v-for="make in makesSorted"
                :key="
                $store.state.vehicleMakeModels.makeNameIdMap[make] +
                  '_makeSearchFilterOption'
              "
                :header-inset-level="0"
                dense
                dense-toggle
                expand-separator
            >
              <template v-slot:header>
                <!--<q-item-section side>
                  <q-checkbox dense :model-value="makeIdsCheckboxState[makeId]" :disable="true" />
                </q-item-section>-->
                <q-item-section>
                  <q-item-label>{{ make }}</q-item-label>
                </q-item-section>
              </template>

              <q-item
                  :key="
                  $store.state.vehicleMakeModels.makeNameIdMap[make] +
                    '_all_modelSearchFilterOption'
                "
                  :inset-level="0.3"
                  v-ripple
                  tag="label"
                  dense
              >
                <q-item-section side>
                  <!--<q-checkbox dense v-model="searchFilterModels.make" :val="makeId" @update:model-value="(value) => toggleMakeModelCheckboxes(value, makeId)" />-->
                  <q-checkbox
                      dense
                      v-model="searchFilterModels.make"
                      :val="$store.state.vehicleMakeModels.makeNameIdMap[make]"
                      color="secondary"
                  />
                </q-item-section>
                <q-item-section>
                  All Models
                </q-item-section>
              </q-item>

              <q-item
                  v-for="modelId in $store.state.vehicleMakeModels
                  .makeIdModelIdsMap[
                  $store.state.vehicleMakeModels.makeNameIdMap[make]
                ]"
                  :key="
                  $store.state.vehicleMakeModels.makeNameIdMap[make] +
                    '_' +
                    modelId +
                    '_modelSearchFilterOption'
                "
                  :inset-level="0.3"
                  v-ripple
                  tag="label"
                  dense
              >
                <q-item-section side>
                  <!--<q-checkbox dense v-model="searchFilterModels.model" :val="modelId" @update:model-value="(value) => toggleMakeCheckboxes(value, makeId)" />-->
                  <q-checkbox
                      dense
                      v-model="searchFilterModels.model"
                      :val="modelId"
                      color="secondary"
                  />
                </q-item-section>
                <q-item-section>
                  <span class="ellipsis">{{
                      $store.state.vehicleMakeModels.modelIdNameMap[modelId]
                    }}</span>
                </q-item-section>
              </q-item>
            </q-expansion-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Body Type">
            <q-item
                v-for="(option, i) in searchFilterOptions.bodyType"
                :key="i + '_bodyTypeSearchFilterOption'"
                :inset-level="0"
                v-ripple
                tag="label"
                dense
            >
              <q-item-section side>
                <q-checkbox
                    dense
                    v-model="searchFilterModels.bodyType"
                    :val="option.value"
                    color="secondary"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Condition">
            <q-item
                v-for="(option, i) in searchFilterOptions.condition"
                :key="i + '_conditionSearchFilterOption'"
                :inset-level="0"
                v-ripple
                tag="label"
                dense
            >
              <q-item-section side>
                <q-checkbox
                    dense
                    v-model="searchFilterModels.condition"
                    :val="option.value"
                    color="secondary"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Price">
            <q-item>
              <div class="row full-width">
                <q-input
                    filled
                    dense
                    v-model.number="searchFilterModels.price.min"
                    label="Min Price"
                    key="minPrice_SearchFilterInput"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="col-4"
                    debounce="750"
                />
                <div class="col-4" />
                <q-input
                    filled
                    dense
                    v-model.number="searchFilterModels.price.max"
                    label="Max Price"
                    key="maxPrice_SearchFilterInput"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="col-4"
                    debounce="750"
                />
              </div>
            </q-item>
            <q-item>
              <q-range
                  v-model="searchFilterModels.price"
                  :min="searchFilterModelDefaults.price.min"
                  :max="searchFilterModelDefaults.price.max"
                  :step="100"
                  color="secondary"
                  label
                  key="price_SearchFilterRange"
                  style="width:94%;margin:0 auto"
              />
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Mileage">
            <q-item>
              <div class="row full-width">
                <q-input
                    filled
                    dense
                    v-model.number="searchFilterModels.mileage.min"
                    label="Min Mileage"
                    key="minMileage_SearchFilterInput"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="col-4"
                    debounce="750"
                />
                <div class="col-4" />
                <q-input
                    filled
                    dense
                    v-model.number="searchFilterModels.mileage.max"
                    label="Max Mileage"
                    key="maxMileage_SearchFilterInput"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="col-4"
                    debounce="750"
                />
              </div>
            </q-item>
            <q-item>
              <q-range
                  v-model="searchFilterModels.mileage"
                  :min="searchFilterModelDefaults.mileage.min"
                  :max="searchFilterModelDefaults.mileage.max"
                  :step="100"
                  color="secondary"
                  label
                  key="mileage_SearchFilterRange"
                  style="width:94%;margin:0 auto"
              ></q-range>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Year">
            <q-item>
              <div class="row full-width">
                <q-input
                    filled
                    dense
                    v-model.number="searchFilterModels.year.min"
                    label="Min Year"
                    key="minYear_SearchFilterInput"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="col-4"
                    debounce="750"
                />
                <div class="col-4" />
                <q-input
                    filled
                    dense
                    v-model.number="searchFilterModels.year.max"
                    label="Max Year"
                    key="maxYear_SearchFilterInput"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="col-4"
                    debounce="750"
                />
              </div>
            </q-item>
            <q-item>
              <q-range
                  v-model="searchFilterModels.year"
                  :min="searchFilterModelDefaults.year.min"
                  :max="searchFilterModelDefaults.year.max"
                  :step="1"
                  color="secondary"
                  label
                  key="year_SearchFilterRange"
                  style="width:94%;margin:0 auto"
              ></q-range>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Color">
            <q-item
                v-for="(option, i) in searchFilterOptions.color"
                :key="i + '_colorSearchFilterOption'"
                :inset-level="0"
                v-ripple
                tag="label"
                dense
            >
              <q-item-section side>
                <q-checkbox
                    dense
                    v-model="searchFilterModels.color"
                    :val="option.value"
                    color="secondary"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
              <q-item-section side>
                <div
                    v-if="option.value === 'white'"
                    :style="{ background: option.color }"
                    style="border: 2px solid #E0E0E0; border-radius: 200px; width: 20px; height: 20px"
                />
                <div
                    v-else-if="option.value === 'brown'"
                    style="background: #DBC5A4; border-radius: 200px; width: 20px; height: 20px; position: relative"
                >
                  <div
                      style="position: absolute; top: 0; left: 0; width: 50%; height: 100%; background: #914915; border-top-left-radius: 200px; border-bottom-left-radius: 200px"
                  ></div>
                </div>
                <div
                    v-else
                    :style="{ background: option.color }"
                    style="border-radius: 200px; width: 20px; height: 20px"
                />
              </q-item-section>
            </q-item>
          </q-expansion-item>
          <q-expansion-item expand-separator label="Fuel Type">
            <q-item
                v-for="(fuelType, i) in searchFilterOptions.fuelType"
                :key="i + '_fuelTypeSearchFilterOption'"
                :inset-level="0"
                v-ripple
                tag="label"
                dense
            >
              <q-item-section side>
                <q-checkbox
                    dense
                    v-model="searchFilterModels.fuelType"
                    :val="fuelType.value"
                    color="secondary"
                />
              </q-item-section>
              <q-item-section>
                {{ fuelType.label }}
              </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item expand-separator label="Transmission">
            <q-item
                v-for="(option, i) in searchFilterOptions.transmission"
                :key="i + '_transmissionSearchFilterOption'"
                :inset-level="0"
                v-ripple
                tag="label"
                dense
            >
              <q-item-section side>
                <q-checkbox
                    dense
                    v-model="searchFilterModels.transmission"
                    :val="option.value"
                    color="secondary"
                />
              </q-item-section>
              <q-item-section>
                {{ option.label }}
              </q-item-section>
            </q-item>
          </q-expansion-item>
          <q-expansion-item expand-separator label="Engine Type">
            <q-item
                v-for="(engineType, i) in searchFilterOptions.engineType"
                :key="i + '_engineTypeSearchFilterOption'"
                :inset-level="0"
                v-ripple
                tag="label"
                dense
            >
              <q-item-section side>
                <q-checkbox
                    dense
                    v-model="searchFilterModels.engineType"
                    :val="engineType.value"
                    color="secondary"
                />
              </q-item-section>
              <q-item-section>
                {{ engineType.label }}
              </q-item-section>
            </q-item>
          </q-expansion-item>
        </q-list>
      </q-form>

      <q-card-section
          class="bg-background-light q-py-lg --shadow__top"
          style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 3;"
      >
        <q-btn
            color="secondary"
            label="Apply"
            class="full-width"
            :disable="!$store.state.search.loaded"
            @click="filterSearchResults"
        />
        <q-btn
            flat
            text-color="text-light"
            label="Reset Filters"
            color="text-light"
            class="full-width q-mt-sm"
            @click="resetFilters"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import DialogHeader from "@/components/UI/DialogHeader.vue";

export default {
  name: "FiltersDialog",
  components: { DialogHeader },
  data() {
    return {
      modalRef: "filtersDialog",
      makeIdsCheckboxState: {},
      searchFilterModels: JSON.parse(
          JSON.stringify(this.$store.state.search.filter.models)
      )
    };
  },
  computed: {
    searchFilterOptions() {
      return this.$store.state.search.filter.options;
    },
    searchFilterModelDefaults() {
      return this.$store.state.search.filter.modelDefaults;
    },
    makesSorted() {
      return this.$store.getters["vehicleMakeModels/makesSorted"];
    }
  },
  watch: {
    "searchFilterModels.price.min"(val) {
      if (
          val.length === 0 ||
          val < this.searchFilterModelDefaults.price.min ||
          val > this.searchFilterModels.price.max
      ) {
        this.searchFilterModels.price.min = this.searchFilterModelDefaults.price.min;
      }
    },
    "searchFilterModels.price.max"(val) {
      if (
          val.length === 0 ||
          val > this.searchFilterModelDefaults.price.max ||
          val < this.searchFilterModels.price.min
      ) {
        this.searchFilterModels.price.max = this.searchFilterModelDefaults.price.max;
      }
    },
    "searchFilterModels.year.min"(val) {
      if (
          val.length === 0 ||
          val < this.searchFilterModelDefaults.year.min ||
          val > this.searchFilterModels.year.max
      ) {
        this.searchFilterModels.year.min = this.searchFilterModelDefaults.year.min;
      }
    },
    "searchFilterModels.year.max"(val) {
      if (
          val.length === 0 ||
          val > this.searchFilterModelDefaults.year.max ||
          val < this.searchFilterModels.year.min
      ) {
        this.searchFilterModels.year.max = this.searchFilterModelDefaults.year.max;
      }
    },
    "searchFilterModels.mileage.min"(val) {
      if (
          val.length === 0 ||
          val < this.searchFilterModelDefaults.mileage.min ||
          val > this.searchFilterModels.mileage.max
      ) {
        this.searchFilterModels.mileage.min = this.searchFilterModelDefaults.mileage.min;
      }
    },
    "searchFilterModels.mileage.max"(val) {
      if (
          val.length === 0 ||
          val > this.searchFilterModelDefaults.mileage.max ||
          val < this.searchFilterModels.mileage.min
      ) {
        this.searchFilterModels.mileage.max = this.searchFilterModelDefaults.mileage.max;
      }
    },
    "searchFilterModels.make"() {
      this.updateMakeIdsCheckboxState("make");
    },
    "searchFilterModels.model"() {
      this.updateMakeIdsCheckboxState("model");
    }
  },
  mounted() {
    this.initMakeIdsCheckboxState();
  },
  methods: {
    show() {
      this.$refs[this.modalRef].show();
    },
    hide() {
      this.$refs[this.modalRef].hide();
    },
    filterSearchResults() {
      this.$store.state.search.filter.models = JSON.parse(
          JSON.stringify(this.searchFilterModels)
      );
      this.$store
          .dispatch("search/CHECK_FILTER_MODELS_UPDATED")
          .then(updated => {
            if (updated) {
              this.$store.commit("search/RESET_KEYWORD");
              this.$store.commit("search/RESET_PAGINATION");
              this.$store.commit("search/SET_FILTER_MODELS_ACTIVE");
              this.$store.commit("search/SET_LOADING");
            }
            this.hide();
          });
    },
    resetFilters() {
      this.$store.commit("search/RESET_KEYWORD");
      this.$store.commit("search/RESET_PAGINATION");
      this.$store.commit("search/RESET_FILTERS");
      this.$store.commit("search/SET_LOADING");
      this.hide();
    },
    initMakeIdsCheckboxState() {
      Object.keys(
          this.$store.state.vehicleMakeModels.makeIdModelIdsMap
      ).forEach(makeId => {
        this.makeIdsCheckboxState[makeId] = false;
        let index = this.searchFilterModels.make.indexOf(makeId);
        if (index !== -1) {
          this.makeIdsCheckboxState[makeId] = true;
        } else {
          let thisMakeModelIds = this.$store.state.vehicleMakeModels
              .makeIdModelIdsMap[makeId];
          thisMakeModelIds.every(modelId => {
            if (this.searchFilterModels.model.includes(modelId)) {
              let index = this.searchFilterModels.model.indexOf(modelId);
              if (index !== -1) {
                this.makeIdsCheckboxState[makeId] = "intermediate";
                return false;
              }
            }
            return true;
          });
        }
      });
    },
    updateMakeIdsCheckboxState(filterModelUpdated) {
      Object.keys(
          this.$store.state.vehicleMakeModels.makeIdModelIdsMap
      ).forEach(makeId => {
        if (filterModelUpdated === "make") {
          this.toggleMakeModelCheckboxes(this.searchFilterModels.make, makeId);
        } else {
          this.toggleMakeCheckboxes(this.searchFilterModels.model, makeId);
        }
      });
    },
    resetMakeIdsCheckboxState() {
      Object.keys(this.$store.state.vehicleMakeModels.makeIdNameMap).forEach(
          makeId => {
            this.makeIdsCheckboxState[makeId] = false;
          }
      );
    },
    toggleMakeCheckboxes(value, makeId) {
      let thisMakeModelIds = this.$store.state.vehicleMakeModels
          .makeIdModelIdsMap[makeId];
      let makeHasActiveModels = false;
      thisMakeModelIds.every(modelId => {
        if (this.searchFilterModels.model.includes(modelId)) {
          let index = this.searchFilterModels.model.indexOf(modelId);
          if (index !== -1) {
            this.makeIdsCheckboxState[makeId] = "intermediate";
            makeHasActiveModels = true;
            return false;
          }
        }
        return true;
      });
      if (!makeHasActiveModels) {
        let index = this.searchFilterModels.make.indexOf(makeId);
        this.makeIdsCheckboxState[makeId] = index !== -1;
      } else {
        let index = this.searchFilterModels.make.indexOf(makeId);
        if (index !== -1) {
          this.searchFilterModels.make.splice(index, 1);
        }
      }
    },
    toggleMakeModelCheckboxes(value, makeId) {
      let index = this.searchFilterModels.make.indexOf(makeId);
      if (index !== -1) {
        this.makeIdsCheckboxState[makeId] = true;
        let thisMakeModelIds = this.$store.state.vehicleMakeModels
            .makeIdModelIdsMap[makeId];
        thisMakeModelIds.forEach(modelId => {
          if (this.searchFilterModels.model.includes(modelId)) {
            let index = this.searchFilterModels.model.indexOf(modelId);
            if (index !== -1) {
              this.searchFilterModels.model.splice(index, 1);
            }
          }
        });
      } else {
        let makeHasActiveModels = false;
        let thisMakeModelIds = this.$store.state.vehicleMakeModels
            .makeIdModelIdsMap[makeId];
        thisMakeModelIds.every(modelId => {
          if (this.searchFilterModels.model.includes(modelId)) {
            let index = this.searchFilterModels.model.indexOf(modelId);
            if (index !== -1) {
              makeHasActiveModels = true;
              return false;
            }
          }
          return true;
        });
        if (!makeHasActiveModels) {
          this.makeIdsCheckboxState[makeId] = false;
          let index = this.searchFilterModels.make.indexOf(makeId);
          if (index !== -1) {
            this.searchFilterModels.make.splice(index, 1);
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.auto__srp__filters-mobile {
  background: color(background);
  overflow-y: auto;
  max-height: calc(100% - 194px);

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>